import { combineReducers } from "redux";

import status from "@redux/slices/verifyStatus";

const lastAction = (_state, action) => {
  return action.type;
};

const rootReducer = combineReducers({ status, lastAction });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
