import moment, { Moment } from "moment-timezone";
import { UserProfile } from "@auth0/nextjs-auth0";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LOCAL_TZ } from "@utils/constants";

export interface IVerifyStatus {
  email?: string;
  emailSub?: string;
  emailIsValid?: boolean;
  hasTours?: boolean;
  movingFrom?: string;
  issuingAgency?: string;
  selectionComplete?: boolean;
  acceptedBioTerms?: boolean;
  acceptedBioPolicy?: boolean;
  disclaimerComplete?: boolean;
  lastRetryAttempt?: Moment;
}

const initialState: IVerifyStatus = {
  email: undefined,
  emailSub: undefined,
  emailIsValid: false,
  hasTours: false,
  movingFrom: undefined,
  issuingAgency: undefined,
  selectionComplete: false,
  acceptedBioTerms: false,
  acceptedBioPolicy: false,
  disclaimerComplete: false,
  lastRetryAttempt: null
};

export const setLastRetryAttemptAsync = createAsyncThunk("status/setLastRetryAttemptAsync", () => {
  return Promise.resolve();
});

export const setEmailAsync = createAsyncThunk("status/setEmailAsync", (email: string) => {
  return Promise.resolve(email);
});

export const setEmailIsValidAsync = createAsyncThunk(
  "status/setEmailIsValidAsync",
  (emailSub: string) => {
    return Promise.resolve(emailSub);
  }
);

export const setEmailHasNoToursAsync = createAsyncThunk("status/setEmailHasNoToursAsync", () => {
  return Promise.resolve();
});

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setEmailSub: (state, action: PayloadAction<string>) => {
      state.emailSub = action.payload;
    },
    setEmailIsValid: (state, action: PayloadAction<boolean>) => {
      state.emailIsValid = action.payload;
    },
    setHasTours: (state, action: PayloadAction<boolean>) => {
      state.hasTours = action.payload;
    },
    setMovingFrom: (state, action: PayloadAction<string>) => {
      state.movingFrom = action.payload;
    },
    setIssuingAgency: (state, action: PayloadAction<string>) => {
      state.issuingAgency = action.payload;
    },
    setSelectionComplete: (state, action: PayloadAction<boolean>) => {
      state.selectionComplete = action.payload;
    },
    setBioTerms: (state, action: PayloadAction<boolean>) => {
      state.acceptedBioTerms = action.payload;
    },
    setBioPolicy: (state, action: PayloadAction<boolean>) => {
      state.acceptedBioPolicy = action.payload;
    },
    setDisclaimerComplete: (state, action: PayloadAction<boolean>) => {
      state.disclaimerComplete = action.payload;
    },
    setLastRetryAttempt: (state, action?: PayloadAction<Moment>) => {
      state.lastRetryAttempt = action?.payload || moment().tz(LOCAL_TZ);
    },
    clearLastRetryAttempt: (state) => {
      state.lastRetryAttempt = null;
    },
    rehydrateState: (state, action: PayloadAction<UserProfile>) => {
      state.email = action.payload?.email;
      state.emailSub = String(action.payload?.user_id || "");
      state.emailIsValid = action.payload?.email_verified;
      state.lastRetryAttempt = moment().tz(LOCAL_TZ).subtract(1, "minute");
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setLastRetryAttemptAsync.fulfilled, (state) => {
      state.lastRetryAttempt = moment().tz(LOCAL_TZ);
    });
    builder.addCase(setEmailAsync.fulfilled, (state, action) => {
      state.email = action.payload;
    });
    builder.addCase(setEmailIsValidAsync.fulfilled, (state, action) => {
      state.emailSub = action.payload;
      state.emailIsValid = true;
    });
    builder.addCase(setEmailHasNoToursAsync.fulfilled, (state) => {
      state.email = undefined;
      state.emailSub = undefined;
      state.emailIsValid = false;
    });
  }
});

export const {
  setEmail,
  setEmailSub,
  setEmailIsValid,
  setHasTours,
  setMovingFrom,
  setIssuingAgency,
  setSelectionComplete,
  setBioTerms,
  setBioPolicy,
  setDisclaimerComplete,
  setLastRetryAttempt,
  clearLastRetryAttempt,
  rehydrateState
} = statusSlice.actions;

export default statusSlice.reducer;
