import moment from "moment-timezone";

import { IVerifyStatus } from "@redux/slices/verifyStatus";

import { StepProps, LOCAL_TZ } from "./constants";
import { UseToursResult } from "./useTours";

export const getStatefulStep = (state: IVerifyStatus, tourData?: UseToursResult): number => {
  if (!state.email) {
    return 1; // Steps.email;
  } else if (!state.emailIsValid || !state.emailSub) {
    return 2; // Steps.code;
  } else if (!state.hasTours) {
    return 3; // Steps.tours;
  } else if (
    tourData &&
    moment.tz(tourData.idVerifyDateTime, LOCAL_TZ).add(30, "days").isAfter(moment().tz(LOCAL_TZ))
  ) {
    if (tourData.idVerifyStatus === "Valid" || tourData.idVerifyStatus === "Denied") {
      return 7; // Steps.complete;
    }
    return 6; // Steps.tips;
  } else if (!state.issuingAgency || !state.movingFrom || !state.selectionComplete) {
    return 4; // Steps.states;
  } else if (!state.acceptedBioTerms || !state.acceptedBioPolicy || !state.disclaimerComplete) {
    return 5; // Steps.disclosure;
  }

  return 1; // Steps.email;
};

export const doStatefulNavigation = (props: StepProps): void => {
  const nextStep = getStatefulStep(props.state, props.tourData);
  if (nextStep !== props.currentStep) {
    props.goToStep(nextStep);
  }
};

export default doStatefulNavigation;
