import * as R from "ramda";
import * as RA from "ramda-adjunct";

import { normalize, JumioResult } from "./constants";
import { NameCombination, nameCombinations } from "./nameCombinations";
import { doInterzoidNameCheck, InterzoidResponse } from "./doInterzoidNameCheck";

export type NamePairResult = {
  firstName: string;
  lastName: string;
  result?: InterzoidResponse;
};

export type CompareJumioNameAgainstProspectNamesResult = {
  matchFound: boolean;
  jumioResult: JumioResult;
  jumioNamePairs?: NamePairResult[];
  prospectNamePairs?: NamePairResult[];
};

export const compareJumioNameAgainstProspectNames = async (
  idFirstName: string,
  idLastName: string,
  prospects: Array<NameCombination>,
  jumioResult: JumioResult,
  allowSimpleCheck = true
): Promise<CompareJumioNameAgainstProspectNamesResult> => {
  const jumioNamePairs: NamePairResult[] = [];
  const prospectNamePairs: NamePairResult[] = [];
  const failedResult = Object.assign({}, jumioResult);
  const isMononym = RA.isNilOrEmpty(idLastName) || normalize(idLastName) === "n/a";
  const jumioNameCombinations = nameCombinations(normalize(idFirstName), normalize(idLastName));

  const prospectNames = R.pipe(
    R.map<NameCombination, NameCombination>(({ firstName, lastName }) => ({ firstName, lastName })),
    R.uniqBy<NameCombination, string>(({ firstName, lastName }) =>
      `${normalize(firstName)} ${normalize(lastName)}`.trim()
    ),
    R.filter<NameCombination>(
      ({ firstName, lastName }) =>
        RA.isNotNilOrEmpty(firstName) && (isMononym || RA.isNotNilOrEmpty(lastName))
    )
  )(prospects);
  const prospectNameCombinations = prospectNames.flatMap(({ firstName, lastName }) =>
    nameCombinations(normalize(firstName), normalize(lastName))
  );

  if (RA.isNilOrEmpty(idFirstName) || (RA.isNilOrEmpty(idLastName) && !isMononym)) {
    failedResult.status = "Not Readable";
    failedResult.message = "Unable to find name of prospect from Jumio";

    return {
      matchFound: false,
      jumioResult: failedResult,
      jumioNamePairs: jumioNameCombinations,
      prospectNamePairs: prospectNameCombinations
    };
  } else if (R.isEmpty(prospects) || R.isEmpty(prospectNames)) {
    failedResult.status = "Not Readable";
    failedResult.message = "Unable to find data of prospect from Salesforce";

    return {
      matchFound: false,
      jumioResult: failedResult,
      jumioNamePairs: jumioNameCombinations,
      prospectNamePairs: prospectNameCombinations
    };
  }

  if (allowSimpleCheck) {
    const simpleMatches = R.innerJoin(
      (prospectName, jumioName) =>
        prospectName.firstName.localeCompare(jumioName.firstName) === 0 &&
        (isMononym || prospectName.lastName.localeCompare(jumioName.lastName) === 0),
      prospectNameCombinations,
      jumioNameCombinations
    );

    if (simpleMatches.length > 0) {
      return {
        matchFound: true,
        jumioResult,
        jumioNamePairs: jumioNameCombinations,
        prospectNamePairs: prospectNameCombinations
      };
    }
  }

  for (const { firstName, lastName } of jumioNameCombinations) {
    const idResult = await doInterzoidNameCheck(firstName, lastName);
    jumioNamePairs.push({
      firstName,
      lastName,
      result: idResult
    });
  }
  const jumioNameHashes = RA.compact(jumioNamePairs.map((r) => r.result?.SimKey));

  for (const { firstName, lastName } of prospectNameCombinations) {
    const prospectResult = await doInterzoidNameCheck(firstName, lastName);
    prospectNamePairs.push({ firstName, lastName, result: prospectResult });
    if (prospectResult?.Error) {
      failedResult.status = "Not Readable";
      failedResult.message = prospectResult.Error;

      return {
        matchFound: false,
        jumioResult: failedResult,
        jumioNamePairs,
        prospectNamePairs
      };
    } else if (jumioNameHashes.includes(prospectResult?.SimKey)) {
      return {
        matchFound: true,
        jumioResult,
        jumioNamePairs,
        prospectNamePairs
      };
    }
  }

  failedResult.status = "Not Readable";
  failedResult.message = "Mismatching name hash";

  return {
    matchFound: false,
    jumioResult: failedResult,
    jumioNamePairs,
    prospectNamePairs
  };
};
