import axios from "axios";
import { UserProfile } from "@auth0/nextjs-auth0";

import { getErrorMessage } from "./constants";

const scope = "openid profile email offline_access";

export type TokenResult = {
  access_token?: string;
  refresh_token?: string;
  id_token?: string;
  scope?: string;
  expires_in?: number;
  token_type?: string;
};

export type AuthError = {
  error?: string;
  error_description?: string;
};

export const start = async (email: string): Promise<UserProfile> => {
  return new Promise<UserProfile>((resolve, reject) => {
    axios
      .post<UserProfile>(
        `${process.env.AUTH0_ISSUER_BASE_URL}/passwordless/start`,
        JSON.stringify({
          client_id: process.env.AUTH0_CLIENT_ID,
          client_secret: process.env.AUTH0_CLIENT_SECRET,
          connection: "email",
          email,
          send: "code",
          authParams: {
            scope
          }
        }),
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(
        (result) => {
          if (result.status === 200) {
            resolve(result.data);
          } else {
            reject(result.statusText);
          }
        },
        (error) => {
          reject(getErrorMessage(error));
        }
      );
  });
};

export const verifyEmail = async (email: string, code: string): Promise<TokenResult> => {
  return new Promise<TokenResult>((resolve, reject) => {
    axios
      .post<TokenResult>(
        `${process.env.AUTH0_ISSUER_BASE_URL}/oauth/token`,
        JSON.stringify({
          grant_type: "http://auth0.com/oauth/grant-type/passwordless/otp",
          client_id: process.env.AUTH0_CLIENT_ID,
          client_secret: process.env.AUTH0_CLIENT_SECRET,
          otp: code,
          realm: "email",
          username: email,
          scope
        }),
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(
        (result) => {
          if (result.status === 200) {
            resolve(result.data);
          } else {
            reject(result.statusText);
          }
        },
        (error) => {
          reject(getErrorMessage(error));
        }
      );
  });
};

export const userInfo = async (access_token: string): Promise<UserProfile> => {
  return new Promise<UserProfile>((resolve, reject) => {
    axios
      .get<UserProfile>(`${process.env.AUTH0_ISSUER_BASE_URL}/userInfo`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`
        }
      })
      .then(
        (result) => {
          if (result.status === 200) {
            resolve(result.data);
          } else {
            reject(result.statusText);
          }
        },
        (error) => {
          reject(getErrorMessage(error));
        }
      );
  });
};

export const userInfoFromSub = async (sub: string): Promise<UserProfile> => {
  return new Promise<UserProfile>((resolve, reject) => {
    axios
      .post<TokenResult>(
        `${process.env.AUTH0_ISSUER_BASE_URL}/oauth/token`,
        JSON.stringify({
          grant_type: "client_credentials",
          client_id: process.env.AUTH0_CLIENT_ID,
          client_secret: process.env.AUTH0_CLIENT_SECRET,
          audience: `${process.env.AUTH0_ISSUER_BASE_URL}/api/v2/`
        }),
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(
        (result) => {
          if (result.status === 200) {
            axios
              .get<UserProfile>(`${process.env.AUTH0_ISSUER_BASE_URL}/api/v2/users/${sub}`, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${result.data.access_token}`
                }
              })
              .then(
                (result) => {
                  if (result.status === 200) {
                    resolve(result.data);
                  } else {
                    reject(result.statusText);
                  }
                },
                (error) => {
                  reject(getErrorMessage(error));
                }
              );
          } else {
            reject(result.statusText);
          }
        },
        (error) => {
          reject(getErrorMessage(error));
        }
      );
  });
};

export const refresh = async (refresh_token: string): Promise<TokenResult> => {
  return new Promise<TokenResult>((resolve, reject) => {
    axios
      .post<TokenResult>(
        `${process.env.AUTH0_ISSUER_BASE_URL}/oauth/token`,
        JSON.stringify({
          grant_type: "refresh_token",
          client_id: process.env.AUTH0_CLIENT_ID,
          client_secret: process.env.AUTH0_CLIENT_SECRET,
          refresh_token
        }),
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(
        (result) => {
          if (result.status === 200) {
            resolve(result.data);
          } else {
            reject(result.statusText);
          }
        },
        (error) => {
          reject(getErrorMessage(error));
        }
      );
  });
};
