import * as Sentry from "@sentry/nextjs";
import * as RA from "ramda-adjunct";

import { normalize, getErrorMessage, flattenObject } from "./constants";
import axios, { AxiosResponse } from "./axios";
import logger from "./logger";

export interface InterzoidResponse {
  SimKey?: string;
  Code?: string;
  Credits?: number;
  Error?: string;
}

export const headers = {
  "x-api-key": process.env.INTERZOID_API_KEY
};

export const doInterzoidNameCheck = async (
  firstName: string,
  lastName: string
): Promise<InterzoidResponse> => {
  let result: InterzoidResponse = {};

  const isMononym = RA.isNilOrEmpty(lastName) || normalize(lastName) === "n/a";

  await axios
    .get(
      isMononym
        ? `${process.env.INTERZOID_API_URL}/getfullnamematch?fullname=${encodeURIComponent(
            normalize(firstName)
          )}`
        : `${process.env.INTERZOID_API_URL}/getfullnameparsedmatch?firstname=${encodeURIComponent(
            normalize(firstName)
          )}&lastname=${encodeURIComponent(normalize(lastName))}`,
      { headers }
    )
    .then((response: AxiosResponse<InterzoidResponse>) => {
      if (response.status === 200) {
        result = response.data;
      } else {
        const sentryId = Sentry.captureMessage(response.statusText, {
          extra: flattenObject(response.data),
          level: Sentry.Severity.Warning
        });
        logger.warn("⚠️　Warning in call to Interzoid: %s", sentryId);
        result = { Error: response.statusText };
      }
    })
    .catch((error) => {
      const sentryId = Sentry.captureException(error, { extra: { firstName, lastName } });
      logger.error(error, "🚨 Error in call to Interzoid: %s", sentryId);
      result = { Error: getErrorMessage(error).message };
    });

  return result;
};
