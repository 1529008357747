import * as R from "ramda";

export type NameCombination = {
  firstName: string;
  lastName: string;
};

export const nameCombinations = (f: string, l: string): Array<NameCombination> =>
  R.uniqBy(
    ({ firstName, lastName }) => firstName + lastName,
    [
      { firstName: f, lastName: l }, // keep original name
      ...R.xprod(
        [...f.split(" ").filter((x) => x !== "jr"), f.replace("-", ""), ...f.split("-")],
        [...l.split(" "), l.replace("-", ""), ...l.split("-")]
      ).map(([firstName, lastName]) => ({
        firstName,
        lastName
      }))
    ]
  );
