import axios from "axios";

import { getErrorMessage, JumioResponse } from "./constants";

export const getJumioUrl = (
  issuingAgency: string,
  movingFrom: string,
  email: string,
  emailSub: string
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const workflowId: number = issuingAgency === "IL" || movingFrom === "IL" ? 100 : 200;

    axios
      .post<JumioResponse>("/api/jumio", { workflowId, email, emailSub })
      .then((result) => {
        if (result.data.redirectUrl) {
          resolve(result.data.redirectUrl);
        } else {
          reject(result.statusText);
        }
      })
      .catch((error) => {
        reject(getErrorMessage(error).message);
      });
  });
};

export default getJumioUrl;
