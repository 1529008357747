import * as os from "os";
import crypto from "crypto";
import axios, { AxiosRequestConfig } from "axios";
import Agent, { HttpOptions } from "agentkeepalive";

import logger from "./logger";

type AxiosRequestConfigWithMetadata = AxiosRequestConfig & {
  metadata?: {
    endTime?: Date;
    startTime?: Date;
    instance?: string;
    duration?: number;
  };
};

const traceRequestDurationStart = (config: AxiosRequestConfigWithMetadata) => {
  config.metadata = {
    startTime: new Date(),
    instance: process?.env?.GAE_INSTANCE || os.hostname()
  };
};

const traceRequestDurationEnd = (config: AxiosRequestConfigWithMetadata) => {
  if (!config?.metadata?.startTime) {
    return;
  }

  config.metadata.endTime = new Date();
  config.metadata.duration =
    config.metadata.endTime.valueOf() - config.metadata.startTime.valueOf();
  logger.info(
    `⌛ ${config.method} to "${config.url}" on "${config.metadata.instance}" took ${Number(
      config.metadata.duration
    ).toFixed(4)}ms.`
  );
};

axios.interceptors.request.use(
  (config) => {
    traceRequestDurationStart(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    traceRequestDurationEnd(response.config);
    return response;
  },
  (error) => {
    traceRequestDurationEnd(error.config);
    return Promise.reject(error);
  }
);

const agentDefaultSettings: HttpOptions = {
  timeout: 60000,
  freeSocketTimeout: 30000
};

axios.defaults.httpAgent = new Agent(agentDefaultSettings);
axios.defaults.httpsAgent = new Agent.HttpsAgent({
  ...agentDefaultSettings,
  secureOptions: crypto.constants.SSL_OP_LEGACY_SERVER_CONNECT
});

export type { AxiosResponse } from "axios";
export { AxiosError } from "axios";
export default axios;
