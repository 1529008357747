export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageView = (page_path: string): void => {
  window.gtag("event", "page_view", {
    page_path,
    send_to: GA_TRACKING_ID
  });
};

type GTagEvent = {
  action: string;
  category: string;
  label: string;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const pageEvent = ({ action, category, label }: GTagEvent): void => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label
  });
};
