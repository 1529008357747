import { AppProps, NextWebVitalsMetric } from "next/app";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@styles/global.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "@redux/store";
import { Spinner } from "react-bootstrap";
import { pageView } from "@utils";
import Head from "next/head";

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (path: string) => {
      pageView(path);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("hashChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <title>Tour ID Verification</title>
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <Provider store={store}>
        <PersistGate
          persistor={persistor}
          loading={<Spinner animation="border" className="spinner" />}>
          <Component {...pageProps} />
        </PersistGate>
      </Provider>
    </>
  );
}

export function reportWebVitals(metric: NextWebVitalsMetric): void {
  window.gtag("event", metric.name, {
    event_category: metric.label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    value: Math.round(metric.name === "CLS" ? metric.value * 1000 : metric.value),
    event_label: metric.id,
    non_interaction: true
  });
}

export default MyApp;
