/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosError } from "axios";
import { MouseEventHandler } from "react";
import moment, { Moment } from "moment-timezone";
import { UserProfile } from "@auth0/nextjs-auth0";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

import { IVerifyStatus } from "@redux/slices/verifyStatus";

import { TourDetail, UseToursResult } from "./useTours";

export const CACHE_KEY = "irvineApiToken";
export const LOCAL_TZ = "America/Los_Angeles";

export const Steps = Object.freeze({
  email: "email",
  code: "code",
  tours: "tours",
  states: "aboutYou",
  disclosure: "disclosures",
  tips: "tips",
  complete: "complete"
});

export type StepProps = {
  hashKey?: string;
  state?: IVerifyStatus;
  tourData?: UseToursResult;
  tourDataValidating?: boolean;
  isActive?: boolean;
  currentStep?: number;
  totalSteps?: number;
  firstStep?: Function;
  lastStep?: Function;
  nextStep?: Function;
  previousStep?: Function;
  goToStep?: Function;
};

export type ModalProps = {
  show: boolean;
  oneClickLegal: boolean;
  onHide: () => void;
  onAccept: MouseEventHandler<HTMLElement>;
};

export type JumioRequest = {
  customerInternalReference?: string;
  userReference?: string;
  reportingCriteria?: string;
  successUrl?: string;
  errorUrl?: string;
  callbackUrl?: string;
  workflowId: string;
  presets?: Object;
  locale?: string;
  tokenLifetimeInMinutes?: number;
};

export type JumioResponse = {
  timestamp?: Moment;
  redirectUrl?: string;
  transactionReference?: string;
};

export type IdentityVerification = {
  similarity: string;
  validity: boolean;
  reason: string;
};

export type RejectReasonDetails = {
  detailsCode: string;
  detailsDescription: string;
};

export type RejectReason = {
  rejectReasonCode: string;
  rejectReasonDescription: string;
  rejectReasonDetails: RejectReasonDetails | Array<RejectReasonDetails>;
};

export type Address = {
  line1?: string;
  line2?: string;
  line3?: string;
  line4?: string;
  line5?: string;
  country?: string;
  postalCode?: string;
  city?: string;
  subdivision?: string;
  formattedAddress?: string;
};

export type JumioCallback = {
  callBackType: string;
  jumioIdScanReference: string;
  verificationStatus: string;
  idScanStatus: string;
  idScanSource: string;
  idCheckDataPositions: string;
  idCheckDocumentValidation: string;
  idCheckHologram: string;
  idCheckMRZcode?: string;
  idCheckMicroprint: string;
  idCheckSecurityFeatures: string;
  idCheckSignature: string;
  transactionDate: Moment;
  callbackDate: Moment;
  identityVerification?: IdentityVerification;
  idType: string;
  idSubtype?: string;
  idCountry: string;
  rejectReason?: RejectReason;
  idScanImage?: string;
  idScanImageFace?: string;
  idScanImageBackside?: string;
  idNumber?: string;
  idFirstName?: string;
  idLastName?: string;
  idDob?: Date;
  idExpiry?: Date;
  idUsState?: string;
  personalNumber?: string;
  idAddress?: Address;
  merchantIdScanReference?: string;
  merchantReportingCriteria?: string;
  customerId?: string;
  clientIp: string;
  firstAttemptDate?: Moment;
  optionalData1?: string;
  optionalData2?: string;
  dni?: string;
  curp?: string;
  gender?: string;
  presetCountry?: string;
  presetIdType: string;
  dlCarPermission?: string;
  dlCategories?: string;
  nationality?: string;
  passportNumber?: string;
  durationOfStay?: string;
  numberOfEntries?: string;
  visaCategory?: string;
  originDob?: string;
  issuingAuthority?: string;
  issuingDate?: string;
  issuingPlace?: string;
  livenessImages?: string;
  placeOfBirth?: string;
  facemap?: string;
  taxNumber?: string;
  cpf?: string;
  registrationNumber?: string;
  mothersName?: string;
  fathersName?: string;
  personalIdentificationNumber?: string;
  rgNumber?: string;
  voterIdNumber?: string;
  issuingNumber?: string;
};

export type LeasingAgent = {
  LeasingAgentId?: string;
  PivotalLeasingAgentId?: string;
  FirstName?: string;
  LastName?: string;
};

export type Tour = {
  TourId?: string;
  PivotalTourId?: string;
  CommunityId?: string;
  PropertyId?: string;
  CommunityName?: string;
  StartTime?: Date;
  Status?: string;
  WaiverSigned?: boolean;
  Units?: unknown;
  LeasingAgent?: LeasingAgent;
  LeasingEmailAddress?: string;
  LeasingPhoneNumber?: string;
  HyLyTourId?: string;
  ModelTour?: boolean;
  WalkUpTour?: boolean;
};

export type Needs = {
  FloorplanGroup?: string;
  LeaseTerm?: string;
  Occupants?: string;
  MoveDate?: Date;
  MinRent?: string;
  MaxRent?: string;
  RentDesired?: string;
  Pets?: boolean;
  Vehicles?: string;
  Floor?: string;
};

export type IdVerificationStatus = "" | "Valid" | "Not Readable" | "Denied" | "Incomplete";

export type JumioResult = {
  verificationStatus: string;
  rejectReasonCode?: string;
  detailsCode?: string;
  validity?: boolean;
  similarity?: string;
  reason?: string;
  message: string;
  status: string; // IdVerificationStatus
};

export type SGTResult = {
  ContactId?: string;
  PivotalContactId?: string;
  FirstName?: string;
  LastName?: string;
  Email?: string;
  Phone?: string;
  IdVerificationStatus?: IdVerificationStatus;
  IdVerificationDateTime?: "" | Date;
  Needs?: Needs;
  Tours?: Array<Tour>;
};

export type SGTResponse = {
  Email?: string;
  StartDate?: Date;
  Results?: Array<SGTResult>;
};

export type JumioRedirectQueryStrings = {
  errorCode?: string;
  transactionStatus?: string;
  transactionReference?: string;
  customerInternalReference?: string;
};

export type JumioRedirectProps = {
  profile?: UserProfile;
  error?: string;
};

export const normalize = (input: string): string => {
  return input.toLocaleLowerCase().trim();
};

export const flattenObject = (obj: Object, parentKey?: string): {} => {
  let result = {};

  Object.entries(obj).forEach(([key, value]) => {
    const _key = parentKey ? `${parentKey}.${key}` : key;
    if (typeof value === "object") {
      result = { ...result, ...flattenObject(value, _key) };
    } else {
      result[_key] = value;
    }
  });

  return result;
};

export const isValidEmail = (email: string): boolean => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

export const isValidCode = (code: string): boolean => {
  return /^\d{6}$/.test(code);
};

export type ApiError = {
  message: string;
  status: number;
};

export const getErrorMessage = (error: Error | AxiosError): ApiError => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      const { data, status } = error.response;
      return { message: JSON.stringify(data), status };
    } else if (error.request) {
      const { response, status } = error.request as XMLHttpRequest;
      return { message: !response ? "Server Error" : JSON.stringify(response), status };
    } else {
      return {
        message: !error.message ? "Server Error" : error.message,
        status: parseInt(error.status) | 500
      };
    }
  }

  return { message: error.message, status: 500 };
};

export const contactInfo = (tourDetail: TourDetail, showTime?: boolean): JSX.Element => {
  return (
    <>
      <b>{tourDetail.communityName}</b>
      {showTime ? (
        <span className="mb-3 d-block">
          <FontAwesomeIcon
            icon={faCalendarAlt}
            style={{ width: "0.75rem", verticalAlign: "baseline" }}
          />{" "}
          {moment.tz(tourDetail.startTime, LOCAL_TZ).format("MMMM D [at] h:mm a")}
        </span>
      ) : (
        <br />
      )}
      {tourDetail.leasingAgentName && (
        <>
          {tourDetail.leasingAgentName}
          <br />
        </>
      )}
      <a href={`tel:${tourDetail.leasingPhoneNumber}`}>{tourDetail.leasingPhoneNumber}</a>
      <br />
      <a href={`mailto:${tourDetail.leasingEmailAddress}?subject=Tour%20ID%20Verification`}>
        {tourDetail.leasingEmailAddress}
      </a>
    </>
  );
};
