import Pino from "pino";
import * as os from "os";

export const logger = Pino({
  base: {
    hostname: process?.env?.GAE_INSTANCE || os.hostname()
  }
});

export default logger;
